// Importation des fichiers
@import 'styles/variables'
@import 'styles/animations'

// Les animations
.animation__background
  position: relative
  visibility: hidden
  animation: display__background .0001s forwards
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 100%
    background: $primary-color
    visibility: visible
    animation: animation__background .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__width
  width: 0
  animation: animation__width .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_top_small
  opacity: 0
  animation: animation__transform_top_small .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_left_rotate
  opacity: 0
  animation: animation__transform_left_rotate .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_left
  opacity: 0
  animation: animation__transform_left .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_right
  opacity: 0
  animation: animation__transform_right .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

// Le Sass de la page
.container
  display: flex
  justify-content: center
  align-items: center

  .container__project__item
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 70%
    height: 70%
    display: none
    @media screen and ( max-width: 700px )
      width: 90%
      height: 50%
    @media screen and ( max-width: 520px )
      height: 40%
    &.active
      display: block

    .project__item
      position: relative
      height: 100%
      width: 100%

      .project__title
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 100vw
        text-align: center
        a
          &::before
            display: none
        h2
          font-weight: 400
          display: inline-block
          &.animation__background
            animation-delay: .9s
            &::after
              animation-delay: .4s

        .project__link
          font-size: 10rem
          font-weight: 400
          font-family: $accent-font
          -webkit-text-stroke: .2rem $primary-color
          transition: color .4s ease
          &:hover
            color: transparent
            ~ .index__project
              left: calc(50% + 6rem)
              @media screen and ( max-width: 520px )
                left: calc(50% + 3rem)
              span
                width: 10rem
                @media screen and ( max-width: 520px )
                  width: 5rem
          @media screen and ( max-width: 900px )
            font-size: 8rem
          @media screen and ( max-width: 700px )
            font-size: 6rem
          @media screen and ( max-width: 520px )
            font-size: 3.5rem
            -webkit-text-stroke: .1rem $primary-color

        .index__project
          font-size: 2.2rem
          font-weight: 400
          font-family: $accent-font
          position: absolute
          left: calc(50% + 1rem)
          display: flex
          justify-content: center
          align-items: center
          transition: left .4s cubic-bezier(0.4, 0, 0.2, 1)
          @media screen and ( max-width: 520px )
            font-size: 1.6rem

          span
            display: block
            width: 15rem
            height: .3rem
            margin: 0 2rem
            transition: width .4s cubic-bezier(0.4, 0, 0.2, 1)
            @media screen and ( max-width: 520px )
              width: 7rem
              margin: 0 1rem
              height: .1rem

            .animation__width
              height: 100%
              background: $primary-color
              animation-delay: 1.1s

          .animation__transform_top_small
            animation-delay: 1.3s

      .item__container
        display: flex
        justify-content: space-between
        align-items: center
        height: 100%

        .left__container
          display: flex
          justify-content: space-between
          align-items: flex-start
          flex-basis: 50%
          height: 100%

          .container__image
            width: 40rem
            height: 100%
            @media screen and ( max-width: 900px )
              width: 30rem
            @media screen and ( max-width: 700px )
              width: 100%
            img
              height: 100%
              width: 0
              object-fit: cover
              &.animation__width
                animation-delay: 1.1s

          .services__container
            transform: rotate(90deg)
            margin-top: 6rem
            margin-right: 1rem
            @media screen and ( max-width: 700px )
              display: none

            .project__services
              font-size: 2.2rem
              font-weight: 700
              font-family: $main-font
              width: 300%
              &.animation__transform_top_small
                animation-delay: 1.7s

            .project__year
              font-size: 1.8rem
              font-weight: 500
              font-family: $main-font
              margin-top: .4rem
              &.animation__transform_top_small
                animation-delay: 1.9s

        .right__container
          display: flex
          align-items: flex-start
          justify-content: flex-end
          flex-basis: 50%

          .container__number_of_projects
            display: flex
            align-items: center
            justify-content: center
            transform: rotate(90deg)
            font-size: 2rem
            font-weight: 500
            font-family: $main-font
            position: absolute
            top: 2rem
            right: 0
            &.animation__transform_left_rotate
              animation-delay: 1.7s
            @media screen and ( max-width: 520px )
              display: none

            p
              &.animation__transform_left, &.animation__transform_right
                animation-delay: 2.2s

            span
              display: block
              width: 2rem
              height: .3rem
              background: $primary-color
              margin: 0 1rem
