// Importation des fichiers
@import 'styles/variables'

// Le Sass de la page
.container
  display: flex
  justify-content: space-between
  align-items: center
  width: calc(100% - 45px * 2)
  position: fixed
  z-index: 20
  &::before
    // A cause du padding de layout, on rajoute le background du header de cette manière --> pas très propre mais plus simple
    content: ''
    position: absolute
    top: -100rem
    left: -100rem
    width: calc(100rem + 200%) // Pour prendre toute la largeur en comptant le décalage à cause du padding
    height: calc(100rem + 100% + 1.5rem) // Pour prendre toute la hauteur en comptant le décalage à cause du padding --> + 2rem pour le bas
    background-color: $accent-color
  @media screen and ( max-width: 520px )
    width: calc(100% - 20px * 2)
    &::before
      height: calc(100rem + 100% + 1rem)

  .logo__container
    font-size: 1.8rem
    font-weight: 500
    font-family: $main-font
    z-index: 30
    @media screen and ( max-width: 520px )
      font-size: 1.6rem

    .logo__url
      display: inline

    a
      &::before
        width: 0
