// Importation des fichiers
@import 'styles/variables'
@import 'styles/animations'

// Les animations
.animation__transform_top
  opacity: 0
  animation: animation__transform_top .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__background
  position: relative
  visibility: hidden
  animation: display__background .0001s forwards
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 100%
    background: $primary-color
    visibility: visible
    animation: animation__background .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

// Le Sass de la page
.container
  .text
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 80%
    text-align: center
    @media screen and ( max-width: 520px )
      text-align: left
      width: 85%

    p
      font-size: 2.6rem
      font-weight: 400
      font-family: $main-font
      animation-delay: .4s
      @media screen and ( max-width: 520px )
        font-size: 2rem

      span
        font-style: italic
        font-weight: 300

  .link__contact
    position: absolute
    bottom: 7rem
    right: 15%
    &.animation__background
      animation-delay: 1.5s
      &::after
        animation-delay: 1s
    @media screen and ( max-width: 520px )
      bottom: 2rem
      right: calc(100% - 2rem)
      transform: translateX(100%)
