// Importation des fichiers
@import 'styles/variables'

// Le Sass de la page uniquement
.container
  padding: 25px 45px
  position: relative
  min-height: 100vh
  @media screen and ( max-width: 520px )
    padding: 20px
