// Importation des fichiers
@import 'styles/variables'
@import 'styles/animations'

// Les animations
.animation__opacity
  opacity: 0
  animation: animation__opacity .4s ease forwards

// Le Sass de la page
.container
  width: 100dvw
  height: 100dvh
  background: rgba($accent-color, .7)
  position: fixed
  top: 0
  left: 0
  z-index: 30
  .alert__container
    position: absolute
    top: 4rem
    left: 50%
    z-index: 1
    transform: translate(-50%, 100%)
    @media screen and ( max-width: 580px )
      top: auto
      bottom: 0.8rem
      transform: translate(-50%, -100%)
    .alert__wrapper
      animation: animation__transform_top_small .8s cubic-bezier(0.4, 0, 0.2, 1)
      .alert
        padding: 0.8rem 1.2rem
        background: $light-green
        color: $accent-color
        font-size: 1.6rem
        font-weight: 400
        font-family: $secondary-font
        border-radius: 0.8rem
        box-shadow: 0 0 1.6rem rgba(0, 0, 0, .2)

  .wrapper
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 80%
    height: 70%
    @media screen and ( max-width: 580px )
      height: 80%
    .inner
      display: flex
      gap: 1.6rem
      position: relative
      width: 100%
      height: 100%
      .image
        object-fit: cover
        border: solid 2px $primary-color
        border-radius: 0.8rem
        flex: 1
        width: 33.33%
        @media screen and ( max-width: 1000px )
          display: none
      .content__wrapper
        display: flex
        flex-direction: column
        justify-content: space-between
        flex: 2
        object-fit: cover
        border: solid 2px $primary-color
        border-radius: 0.8rem
        background-position: bottom left
        background-size: cover
        padding: 3.6rem 4.8rem
        @media screen and ( max-width: 700px )
          padding: 2.4rem 3.2rem
        @media screen and ( max-width: 580px )
          padding: 1.6rem 2.4rem
        .header__wrapper
          flex: 1
          .header
            display: flex
            justify-content: space-between
            .email__container
              display: flex
              flex-direction: column
              user-select: none
              cursor: pointer
              .email__wrapper
                display: flex
                align-items: center
                .email__name, .email__domain
                  font-size: 2.4rem
                  font-weight: 700
                  font-family: $secondary-font
                  color: $light-green
                .email__at
                  position: relative
                  svg
                    width: 2.4rem
                    height: auto
                  .email__at__hover
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    svg
                      path
                        stroke-dasharray: 1000
                        stroke-dashoffset: 1000
                        transition: stroke-dashoffset .8s cubic-bezier(0.4, 0, 0.2, 1)
              .line__wrapper
                svg
                  width: 22rem
                  height: auto
                  path
                    stroke-dasharray: 1000
                    stroke-dashoffset: 1000
                    transition: stroke-dashoffset .8s cubic-bezier(0.4, 0, 0.2, 1)
              &:hover
                .email__at
                  .email__at__hover
                    svg
                      path
                        stroke-dashoffset: 800
                .line__wrapper
                  svg
                    path
                      stroke-dashoffset: 700

            .logo
              height: 2.8rem
              @media screen and ( max-width: 700px )
                display: none
              &::before
                content: none
              img
                height: 100%
                width: auto

        .content
          flex: 3
          z-index: 1
          h1
            font-size: 5.6rem
            font-weight: 700
            font-family: $secondary-font
            margin-bottom: 2rem
            @media screen and ( max-width: 580px )
              font-size: 4.8rem
          p
            font-size: 2.8rem
            font-weight: 400
            font-family: $secondary-font
            @media screen and ( max-width: 580px )
              font-size: 2.4rem
          .link__wrapper
            margin-top: 3.2rem
            display: inline-flex
            align-items: center
            gap: 2.4rem
            &::before
              content: none
            .arrow__wrapper
              transition: transform .8s cubic-bezier(0.4, 0, 0.2, 1)
              @media screen and ( max-width: 580px )
                svg
                  width: 2.4rem
                  height: auto
            .link__container
              display: flex
              flex-direction: column
              .link
                font-size: 2.8rem
                font-weight: 700
                font-family: $secondary-font
                color: $light-green
                @media screen and ( max-width: 580px )
                  font-size: 2.4rem
              .line__wrapper
                svg
                  width: 22.5rem
                  height: auto
                  @media screen and ( max-width: 580px )
                    width: 19.5rem
                  path
                    stroke-dasharray: 1000
                    stroke-dashoffset: 1000
                    transition: stroke-dashoffset .8s cubic-bezier(0.4, 0, 0.2, 1)
            &:hover
              .arrow__wrapper
                transform: rotate(45deg)
              .line__wrapper
                svg
                  path
                    stroke-dashoffset: 700

      .kanji
        position: absolute
        bottom: 0
        right: 0
        width: 25%
        height: auto
        opacity: .5
        @media screen and ( max-width: 1000px )
          width: 30%
        @media screen and ( max-width: 700px )
          width: 40%
