@font-face
  font-family: 'Agenda'
  src: url("./fonts/AgendaLight.otf") format("opentype")
  font-weight: 300

@font-face
  font-family: 'Agenda'
  src: url("./fonts/AgendaRegular.ttf") format("truetype")
  font-weight: 400

@font-face
  font-family: 'Agenda'
  src: url("./fonts/AgendaMedium.ttf") format("truetype")
  font-weight: 500

@font-face
  font-family: 'Agenda'
  src: url("./fonts/AgendaBold.ttf") format("truetype")
  font-weight: 700

@font-face
  font-family: 'TheNightWatch'
  src: url("./fonts/TheNightWatch.ttf") format("truetype")
  font-weight: 400

@font-face
  font-family: 'Brinnan'
  src: url("./fonts/BrinnanRegular.otf") format("opentype")
  font-weight: 400

@font-face
  font-family: 'Brinnan'
  src: url("./fonts/BrinnanBold.otf") format("opentype")
  font-weight: 700
