// Importation des fichiers
@import 'styles/variables'

// Le Sass de la page
.container
  display: inline-block
  .link
    font-size: 5rem
    font-weight: 300
    font-family: $main-font
    display: inline-flex
    justify-content: center
    align-items: center
    @media screen and ( max-width: 520px )
      font-size: 3rem

    svg
      margin-left: 5rem
      @media screen and ( max-width: 520px )
        height: 20px
        width: auto
        margin-left: 3rem
