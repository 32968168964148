// Importation des fichiers
@import 'styles/variables'
@import 'styles/animations'

// Les animations
@mixin li-animation
  @for $i from 1 through 10
    &:nth-child(#{$i})
      &.animation__background
        animation-delay: #{1.3 + $i * .2}s
        &::after
          animation-delay: #{.8 + $i * .2}s

.animation__background
  position: relative
  visibility: hidden
  animation: display__background .0001s forwards
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 100%
    background: $primary-color
    visibility: visible
    animation: animation__background .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_left_rotate
  opacity: 0
  animation: animation__transform_left_rotate .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_left
  opacity: 0
  animation: animation__transform_left .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__transform_right
  opacity: 0
  animation: animation__transform_right .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

// Le Sass de la page
.container
  display: flex
  justify-content: center
  align-items: flex-end
  flex-direction: column
  width: 85%
  margin: auto
  margin-top: 20rem
  margin-bottom: 7rem
  @media screen and ( max-width: 900px )
    width: 100%
    margin-top: 15rem
  @media screen and ( max-width: 680px )
    margin-top: 12rem
  @media screen and ( max-width: 520px )
    margin-top: 10rem

  .title
    position: relative
    @media screen and ( max-width: 900px )
      align-self: flex-start
    h1
      font-size: 15rem
      font-weight: 400
      font-family: $accent-font
      &.animation__background
        animation-delay: .9s
        &::after
          animation-delay: .4s
      @media screen and ( max-width: 1130px )
        font-size: 12.3rem
      @media screen and ( max-width: 900px )
        font-size: 10rem
      @media screen and ( max-width: 680px )
        font-size: 7rem
      @media screen and ( max-width: 520px )
        font-size: 4.5rem

    .number_of_projects
      position: absolute
      top: 2rem
      left: -5rem
      transform: rotate(90deg)
      display: flex
      justify-content: center
      align-items: center
      &.animation__transform_left_rotate
        animation-delay: 2s
      @media screen and ( max-width: 900px )
        display: none

      p
        font-size: 2.2rem
        font-weight: 500
        font-family: $main-font
        animation-delay: 2.5s

      span
        display: block
        width: 2rem
        height: .3rem
        background: $primary-color
        margin: 0 1rem

  .about
    width: 40%
    display: flex
    justify-content: center
    align-items: flex-start
    flex-direction: column
    margin-top: 8rem
    @media screen and ( max-width: 680px )
      width: 70%
      margin-top: 4rem
    @media screen and ( max-width: 520px )
      margin-top: 3rem
      width: 80%
    .link
      font-size: 2.6rem
      font-weight: 300
      font-family: $main-font
      display: inline-flex
      justify-content: center
      align-items: center
      &.animation__background
        animation-delay: 1.9s
        &::after
          animation-delay: 1.4s
      @media screen and ( max-width: 520px )
        font-size: 2rem

      svg
        margin-left: 1.5rem

    .container__services
      display: flex
      justify-content: space-between
      align-items: flex-start
      width: 80%
      margin-top: 4rem
      font-size: 1.8rem
      font-weight: 500
      font-family: $main-font
      @media screen and ( max-width: 1130px )
        width: 100%
      @media screen and ( max-width: 520px )
        font-size: 1.4rem
        margin-top: 2.5rem
      ul
        list-style-type: none
        display: flex
        justify-content: center
        align-items: flex-start
        flex-direction: column
        li
          display: inline-block
          margin-bottom: .2rem
          @include li-animation

      .year
        &.animation__background
          animation-delay: 1.9s
          &::after
            animation-delay: 1.4s
