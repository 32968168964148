// Importation des fichiers
@import 'styles/variables'
@import 'styles/animations'

// Les animations
@mixin img-animation
  @for $i from 1 through 10
    &:nth-child(#{$i})
      animation-delay: #{2 + $i * .2}s

.animation__transform_top
  opacity: 0
  animation: animation__transform_top .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

.animation__background
  position: relative
  visibility: hidden
  animation: display__background .0001s forwards
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 100%
    background: $primary-color
    visibility: visible
    animation: animation__background .8s cubic-bezier(0.4, 0, 0.2, 1) forwards

// Le Sass de la page
.container
  margin-bottom: 5rem
  .images__container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    margin-bottom: 3rem

    .animation__transform_top
      @include img-animation

    video, img
      border: .1rem rgba($primary-color, .5) solid
      margin-bottom: 2rem
      width: 100%
      outline: none
      cursor: pointer

    img
      @media screen and ( max-width: 900px )
        cursor: auto

    .row
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      @media screen and ( max-width: 900px )
        flex-direction: column

      img
        width: calc(50% - 2rem / 2)
        @media screen and ( max-width: 900px )
          width: 100%

        &:last-child
          margin-left: 2rem
          @media screen and ( max-width: 900px )
            margin-left: 0

  .text__introduction
    font-size: 1.8rem
    font-weight: 500
    font-family: $main-font
    text-align: center
    margin-bottom: 4rem
    animation-delay: 2s
    @media screen and ( max-width: 520px )
      font-size: 1.6rem

  .footer__project
    display: flex
    justify-content: flex-start
    flex-direction: column
    align-items: flex-end
    margin-right: 16rem
    margin-top: 10rem
    @media screen and ( max-width: 900px )
      margin-right: 8rem
    @media screen and ( max-width: 670px )
      margin-right: 4rem
    @media screen and ( max-width: 570px )
      margin-right: 0
      align-items: flex-start
      margin-top: 3rem

    .animation__background
      animation-delay: 3s
      &::after
        animation-delay: 2.5s
