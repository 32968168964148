// Importation des fichiers
@import 'styles/variables'

body
  overflow: hidden

.container
  .sticker
    position: absolute
    @media screen and ( max-width: 520px )
      display: none
    svg
      min-width: 30rem
      max-width: 40rem
      max-height: 20rem
      min-height: 15rem

  .button
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 80%

    .mobile
      color: $primary-color
      font-size: 1.8rem
      font-weight: 500
      font-family: $main-font
      text-align: center
      margin-bottom: 2rem
      display: none
      @media screen and ( max-width: 520px )
        display: block
