// Importation des fichiers
@import 'styles/variables'

// Le Sass de la page
.container
  display: flex
  justify-content: flex-end
  align-items: center

  .icon__container
    background: $accent-color
    position: relative
    &::after
      content: ''
      position: absolute
      top: 0
      left: 100%
      width: 5rem
      height: 100%
      background: $accent-color

    .icon
      height: 4rem
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      z-index: 30
      .burger__crose
        position: relative
        width: 3.6rem
        height: .25rem
        background: $primary-color
        transition: background .8s cubic-bezier(0.4, 0, 0.2, 1)
        &::before, &::after
          content: ''
          position: absolute
          left: .3rem
          width: 3rem
          height: .25rem
          background: $primary-color
          transition: transform .8s cubic-bezier(0.4, 0, 0.2, 1)
        &::before
          transform: translateY(-1.15rem)
        &::after
          transform: translateY(1.15rem)

      &.active
        .burger__crose
          background: transparent
          &::before
            transform: rotate(45deg)
          &::after
            transform: rotate(-45deg)

  .menu__container
    &.desktop
      transform: translateX(100%)
      opacity: 0
      transition: opacity .8s cubic-bezier(0.4, 0, 0.2, 1), transform .8s cubic-bezier(0.4, 0, 0.2, 1)
      &.active
        transform: translateX(0)
        opacity: 1

      ul
        display: flex
        justify-content: space-between
        align-items: center
        list-style-type: none
        padding: 0
        margin-right: 6rem

        li
          margin-right: 5rem
          &:last-child
            margin-right: 0

          a
            font-size: 1.8rem
            font-weight: 500
            font-family: $main-font
            white-space: nowrap
            &::before
              width: 0
            &:hover
              &::before
                width: 100%

    &.mobile
      display: none
      height: 100vh
      width: 100vw
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      justify-content: center
      align-items: center
      flex-direction: column
      background: $accent-color
      transform: translate(100%, -50%)
      transition: transform .8s cubic-bezier(0.4, 0, 0.2, 1)
      &.active
        transform: translate(-50%, -50%)

      ul
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        list-style-type: none
        padding: 0

        li
          margin-bottom: 3rem
          &:last-child
            margin-bottom: 0

          a
            font-size: 2.5rem
            font-weight: 500
            font-family: $main-font
            &::before
              width: 0
            &:hover
              &::before
                width: 100%

    @media screen and ( max-width: 900px )
      &.desktop
        display: none
      &.mobile
        display: flex
