// Importation des fichiers
@import 'styles/variables'

// Le Sass de la page
.container
  position: fixed
  left: 0
  top: 0
  border-radius: 50%
  border: 2px solid $primary-color
  pointer-events: none
  z-index: 999
  transition: transform .1s ease
  mix-blend-mode: difference

  &.is__hover
    background: red
